<template>
    <nav id="desktop" class="p-0" role="navigation">
        <div class="d-flex justify-content-center pt-3 pb-3 bg-white">
            <!-- ZONA LOGO -->
            <div id="logoMenu" class="">
                <img width="228" height="59" id="ibancarLogo" class="img-fluid" src="@/assets/img/logo_ibancar_azul.svg" alt="Ibancar">
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: 'NavBarInvestor',
    methods: {
        
    }
}
</script>

<style scoped lang="scss">
// clases comunes a todas los tamaños
  #ibancarLogo {
    &.router-link-active {
      border: none;
    }
    &.router-link-exact-active {
      border: none;
    }
  }
  
  .cursor:hover, .cursor:hover:after, .cursor:hover:before,.cursor:hover i, .cursor:hover span {
    cursor: pointer;
  }
 .grey {
   color: #333;
 }
// Clases específicas según tamaño de pantalla:

@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';
@import '@/assets/styles/variables.scss';


// Pantallas muy pequeñas a pequeñas

@include media-breakpoint-between(xs, sm) {
	
}

// Pantallas medianas a Grandes
@include media-breakpoint-between(md, lg) {
	
}

// Pantallas xl y xxl
@include media-breakpoint-between(xl, xxl) {

}

</style>