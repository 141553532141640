<template>
    <div id="tabla" class="bg-img-azul pt-5 bg-orange">
      <div class="container-fluid">
        <div class="row justify-content-center align-content-center flex-wrap">
              <h2 class="col-12 title-h1 text-center p-4">¿Por qué elegirnos?</h2>
        </div>
          <div class="row justify-content-center">
            <div class="col-12 col-md-6 offset-md-3 pt-1">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col"></th>
                      <th class="text-center" scope="col">Ibancar</th>
                      <th class="text-center" scope="col">Otras empresas</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th class="white-text" scope="row">Plazo obtención</th>
                      <td class="green-text text-center">Urgente</td>
                      <td class="red-text text-center">Rápido</td>
                    </tr>
                    <tr>
                      <th class="white-text" scope="row">Condiciones flexibles</th>
                      <td class="text-center">
                        <svg width="15" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path opacity="0.75" d="M15.7084 0.592773L7.11149 9.18368L3.92747 6.00186L0.720703 9.20641L7.11149 15.5928L18.9151 3.79732L15.7084 0.592773Z" fill="#4AC068"/>
                        </svg>
                      </td>
                      <td class="text-center">
                        <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g opacity="0.75">
                          <path d="M12.9306 2.98896L13.3199 2.59992L12.9306 2.21087L11.9228 1.20373L11.534 0.815225L11.1452 1.20373L7.53838 4.80808L3.93153 1.20373L3.54275 0.815225L3.15398 1.20373L2.14614 2.21087L1.75683 2.59992L2.14614 2.98896L5.75245 6.59277L2.14614 10.1966L1.75682 10.5856L2.14614 10.9747L3.15398 11.9818L3.54275 12.3703L3.93153 11.9818L7.53838 8.37746L11.1452 11.9818L11.534 12.3703L11.9228 11.9818L12.9306 10.9747L13.3199 10.5856L12.9306 10.1966L9.3243 6.59277L12.9306 2.98896Z" fill="#FA0000" fill-opacity="0.996"/>
                          <path d="M12.9306 2.98896L13.3199 2.59992L12.9306 2.21087L11.9228 1.20373L11.534 0.815225L11.1452 1.20373L7.53838 4.80808L3.93153 1.20373L3.54275 0.815225L3.15398 1.20373L2.14614 2.21087L1.75683 2.59992L2.14614 2.98896L5.75245 6.59277L2.14614 10.1966L1.75682 10.5856L2.14614 10.9747L3.15398 11.9818L3.54275 12.3703L3.93153 11.9818L7.53838 8.37746L11.1452 11.9818L11.534 12.3703L11.9228 11.9818L12.9306 10.9747L13.3199 10.5856L12.9306 10.1966L9.3243 6.59277L12.9306 2.98896Z" fill="#FF6440"/>
                          <path d="M12.9306 2.98896L13.3199 2.59992L12.9306 2.21087L11.9228 1.20373L11.534 0.815225L11.1452 1.20373L7.53838 4.80808L3.93153 1.20373L3.54275 0.815225L3.15398 1.20373L2.14614 2.21087L1.75683 2.59992L2.14614 2.98896L5.75245 6.59277L2.14614 10.1966L1.75682 10.5856L2.14614 10.9747L3.15398 11.9818L3.54275 12.3703L3.93153 11.9818L7.53838 8.37746L11.1452 11.9818L11.534 12.3703L11.9228 11.9818L12.9306 10.9747L13.3199 10.5856L12.9306 10.1966L9.3243 6.59277L12.9306 2.98896Z" stroke="#FA0000" stroke-width="1.1"/>
                          <path d="M12.9306 2.98896L13.3199 2.59992L12.9306 2.21087L11.9228 1.20373L11.534 0.815225L11.1452 1.20373L7.53838 4.80808L3.93153 1.20373L3.54275 0.815225L3.15398 1.20373L2.14614 2.21087L1.75683 2.59992L2.14614 2.98896L5.75245 6.59277L2.14614 10.1966L1.75682 10.5856L2.14614 10.9747L3.15398 11.9818L3.54275 12.3703L3.93153 11.9818L7.53838 8.37746L11.1452 11.9818L11.534 12.3703L11.9228 11.9818L12.9306 10.9747L13.3199 10.5856L12.9306 10.1966L9.3243 6.59277L12.9306 2.98896Z" stroke="#FF6440" stroke-width="1.1"/>
                          </g>
                        </svg>
                      </td>
                    </tr>
                    <tr>
                      <th class="white-text" scope="row">Necesario ir al Notario</th>
                      <td class="text-center">
                        <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g opacity="0.75">
                          <path d="M12.9306 2.98896L13.3199 2.59992L12.9306 2.21087L11.9228 1.20373L11.534 0.815225L11.1452 1.20373L7.53838 4.80808L3.93153 1.20373L3.54275 0.815225L3.15398 1.20373L2.14614 2.21087L1.75683 2.59992L2.14614 2.98896L5.75245 6.59277L2.14614 10.1966L1.75682 10.5856L2.14614 10.9747L3.15398 11.9818L3.54275 12.3703L3.93153 11.9818L7.53838 8.37746L11.1452 11.9818L11.534 12.3703L11.9228 11.9818L12.9306 10.9747L13.3199 10.5856L12.9306 10.1966L9.3243 6.59277L12.9306 2.98896Z" fill="#4AC068" fill-opacity="0.996"/>
                          <path d="M12.9306 2.98896L13.3199 2.59992L12.9306 2.21087L11.9228 1.20373L11.534 0.815225L11.1452 1.20373L7.53838 4.80808L3.93153 1.20373L3.54275 0.815225L3.15398 1.20373L2.14614 2.21087L1.75683 2.59992L2.14614 2.98896L5.75245 6.59277L2.14614 10.1966L1.75682 10.5856L2.14614 10.9747L3.15398 11.9818L3.54275 12.3703L3.93153 11.9818L7.53838 8.37746L11.1452 11.9818L11.534 12.3703L11.9228 11.9818L12.9306 10.9747L13.3199 10.5856L12.9306 10.1966L9.3243 6.59277L12.9306 2.98896Z" fill="#4AC068"/>
                          <path d="M12.9306 2.98896L13.3199 2.59992L12.9306 2.21087L11.9228 1.20373L11.534 0.815225L11.1452 1.20373L7.53838 4.80808L3.93153 1.20373L3.54275 0.815225L3.15398 1.20373L2.14614 2.21087L1.75683 2.59992L2.14614 2.98896L5.75245 6.59277L2.14614 10.1966L1.75682 10.5856L2.14614 10.9747L3.15398 11.9818L3.54275 12.3703L3.93153 11.9818L7.53838 8.37746L11.1452 11.9818L11.534 12.3703L11.9228 11.9818L12.9306 10.9747L13.3199 10.5856L12.9306 10.1966L9.3243 6.59277L12.9306 2.98896Z" stroke="#4AC068" stroke-width="1.1"/>
                          <path d="M12.9306 2.98896L13.3199 2.59992L12.9306 2.21087L11.9228 1.20373L11.534 0.815225L11.1452 1.20373L7.53838 4.80808L3.93153 1.20373L3.54275 0.815225L3.15398 1.20373L2.14614 2.21087L1.75683 2.59992L2.14614 2.98896L5.75245 6.59277L2.14614 10.1966L1.75682 10.5856L2.14614 10.9747L3.15398 11.9818L3.54275 12.3703L3.93153 11.9818L7.53838 8.37746L11.1452 11.9818L11.534 12.3703L11.9228 11.9818L12.9306 10.9747L13.3199 10.5856L12.9306 10.1966L9.3243 6.59277L12.9306 2.98896Z" stroke="#4AC068" stroke-width="1.1"/>
                          </g>
                        </svg>
                      </td>
                      <td class="text-center">
                        <svg width="15" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path opacity="0.75" d="M15.7084 0.592773L7.11149 9.18368L3.92747 6.00186L0.720703 9.20641L7.11149 15.5928L18.9151 3.79732L15.7084 0.592773Z" fill="#FF6440"/>
                        </svg>
                      </td>
                    </tr>
                    <tr>
                      <th class="white-text" scope="row">Amortiza Capital en cuotas</th>
                      <td class="text-center">
                        <svg width="15" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path opacity="0.75" d="M15.7084 0.592773L7.11149 9.18368L3.92747 6.00186L0.720703 9.20641L7.11149 15.5928L18.9151 3.79732L15.7084 0.592773Z" fill="#4AC068"/>
                        </svg>
                      </td>
                      <td class="text-center">
                        <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g opacity="0.75">
                          <path d="M12.9306 2.98896L13.3199 2.59992L12.9306 2.21087L11.9228 1.20373L11.534 0.815225L11.1452 1.20373L7.53838 4.80808L3.93153 1.20373L3.54275 0.815225L3.15398 1.20373L2.14614 2.21087L1.75683 2.59992L2.14614 2.98896L5.75245 6.59277L2.14614 10.1966L1.75682 10.5856L2.14614 10.9747L3.15398 11.9818L3.54275 12.3703L3.93153 11.9818L7.53838 8.37746L11.1452 11.9818L11.534 12.3703L11.9228 11.9818L12.9306 10.9747L13.3199 10.5856L12.9306 10.1966L9.3243 6.59277L12.9306 2.98896Z" fill="#FF6440" fill-opacity="0.996"/>
                          <path d="M12.9306 2.98896L13.3199 2.59992L12.9306 2.21087L11.9228 1.20373L11.534 0.815225L11.1452 1.20373L7.53838 4.80808L3.93153 1.20373L3.54275 0.815225L3.15398 1.20373L2.14614 2.21087L1.75683 2.59992L2.14614 2.98896L5.75245 6.59277L2.14614 10.1966L1.75682 10.5856L2.14614 10.9747L3.15398 11.9818L3.54275 12.3703L3.93153 11.9818L7.53838 8.37746L11.1452 11.9818L11.534 12.3703L11.9228 11.9818L12.9306 10.9747L13.3199 10.5856L12.9306 10.1966L9.3243 6.59277L12.9306 2.98896Z" fill="#FF6440"/>
                          <path d="M12.9306 2.98896L13.3199 2.59992L12.9306 2.21087L11.9228 1.20373L11.534 0.815225L11.1452 1.20373L7.53838 4.80808L3.93153 1.20373L3.54275 0.815225L3.15398 1.20373L2.14614 2.21087L1.75683 2.59992L2.14614 2.98896L5.75245 6.59277L2.14614 10.1966L1.75682 10.5856L2.14614 10.9747L3.15398 11.9818L3.54275 12.3703L3.93153 11.9818L7.53838 8.37746L11.1452 11.9818L11.534 12.3703L11.9228 11.9818L12.9306 10.9747L13.3199 10.5856L12.9306 10.1966L9.3243 6.59277L12.9306 2.98896Z" stroke="#FF6440" stroke-width="1.1"/>
                          <path d="M12.9306 2.98896L13.3199 2.59992L12.9306 2.21087L11.9228 1.20373L11.534 0.815225L11.1452 1.20373L7.53838 4.80808L3.93153 1.20373L3.54275 0.815225L3.15398 1.20373L2.14614 2.21087L1.75683 2.59992L2.14614 2.98896L5.75245 6.59277L2.14614 10.1966L1.75682 10.5856L2.14614 10.9747L3.15398 11.9818L3.54275 12.3703L3.93153 11.9818L7.53838 8.37746L11.1452 11.9818L11.534 12.3703L11.9228 11.9818L12.9306 10.9747L13.3199 10.5856L12.9306 10.1966L9.3243 6.59277L12.9306 2.98896Z" stroke="#FF6440" stroke-width="1.1"/>
                          </g>
                        </svg>
                      </td>
                    </tr>
                    <tr>
                      <th class="white-text" scope="row">Cuota Final</th>
                      <td class="text-center">
                        <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g opacity="0.75">
                          <path d="M12.9306 2.98896L13.3199 2.59992L12.9306 2.21087L11.9228 1.20373L11.534 0.815225L11.1452 1.20373L7.53838 4.80808L3.93153 1.20373L3.54275 0.815225L3.15398 1.20373L2.14614 2.21087L1.75683 2.59992L2.14614 2.98896L5.75245 6.59277L2.14614 10.1966L1.75682 10.5856L2.14614 10.9747L3.15398 11.9818L3.54275 12.3703L3.93153 11.9818L7.53838 8.37746L11.1452 11.9818L11.534 12.3703L11.9228 11.9818L12.9306 10.9747L13.3199 10.5856L12.9306 10.1966L9.3243 6.59277L12.9306 2.98896Z" fill="#4AC068" fill-opacity="0.996"/>
                          <path d="M12.9306 2.98896L13.3199 2.59992L12.9306 2.21087L11.9228 1.20373L11.534 0.815225L11.1452 1.20373L7.53838 4.80808L3.93153 1.20373L3.54275 0.815225L3.15398 1.20373L2.14614 2.21087L1.75683 2.59992L2.14614 2.98896L5.75245 6.59277L2.14614 10.1966L1.75682 10.5856L2.14614 10.9747L3.15398 11.9818L3.54275 12.3703L3.93153 11.9818L7.53838 8.37746L11.1452 11.9818L11.534 12.3703L11.9228 11.9818L12.9306 10.9747L13.3199 10.5856L12.9306 10.1966L9.3243 6.59277L12.9306 2.98896Z" fill="#4AC068"/>
                          <path d="M12.9306 2.98896L13.3199 2.59992L12.9306 2.21087L11.9228 1.20373L11.534 0.815225L11.1452 1.20373L7.53838 4.80808L3.93153 1.20373L3.54275 0.815225L3.15398 1.20373L2.14614 2.21087L1.75683 2.59992L2.14614 2.98896L5.75245 6.59277L2.14614 10.1966L1.75682 10.5856L2.14614 10.9747L3.15398 11.9818L3.54275 12.3703L3.93153 11.9818L7.53838 8.37746L11.1452 11.9818L11.534 12.3703L11.9228 11.9818L12.9306 10.9747L13.3199 10.5856L12.9306 10.1966L9.3243 6.59277L12.9306 2.98896Z" stroke="#4AC068" stroke-width="1.1"/>
                          <path d="M12.9306 2.98896L13.3199 2.59992L12.9306 2.21087L11.9228 1.20373L11.534 0.815225L11.1452 1.20373L7.53838 4.80808L3.93153 1.20373L3.54275 0.815225L3.15398 1.20373L2.14614 2.21087L1.75683 2.59992L2.14614 2.98896L5.75245 6.59277L2.14614 10.1966L1.75682 10.5856L2.14614 10.9747L3.15398 11.9818L3.54275 12.3703L3.93153 11.9818L7.53838 8.37746L11.1452 11.9818L11.534 12.3703L11.9228 11.9818L12.9306 10.9747L13.3199 10.5856L12.9306 10.1966L9.3243 6.59277L12.9306 2.98896Z" stroke="#4AC068" stroke-width="1.1"/>
                          </g>
                        </svg>
                      </td>
                      <td class="text-center">
                        <svg width="15" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path opacity="0.75" d="M15.7084 0.592773L7.11149 9.18368L3.92747 6.00186L0.720703 9.20641L7.11149 15.5928L18.9151 3.79732L15.7084 0.592773Z" fill="#FF6440"/>
                        </svg>
                      </td>
                    </tr>
                    <tr>
                      <th class="white-text" scope="row">Cambio de Titular</th>
                      <td class="text-center">
                        <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g opacity="0.75">
                          <path d="M12.9306 2.98896L13.3199 2.59992L12.9306 2.21087L11.9228 1.20373L11.534 0.815225L11.1452 1.20373L7.53838 4.80808L3.93153 1.20373L3.54275 0.815225L3.15398 1.20373L2.14614 2.21087L1.75683 2.59992L2.14614 2.98896L5.75245 6.59277L2.14614 10.1966L1.75682 10.5856L2.14614 10.9747L3.15398 11.9818L3.54275 12.3703L3.93153 11.9818L7.53838 8.37746L11.1452 11.9818L11.534 12.3703L11.9228 11.9818L12.9306 10.9747L13.3199 10.5856L12.9306 10.1966L9.3243 6.59277L12.9306 2.98896Z" fill="#4AC068" fill-opacity="0.996"/>
                          <path d="M12.9306 2.98896L13.3199 2.59992L12.9306 2.21087L11.9228 1.20373L11.534 0.815225L11.1452 1.20373L7.53838 4.80808L3.93153 1.20373L3.54275 0.815225L3.15398 1.20373L2.14614 2.21087L1.75683 2.59992L2.14614 2.98896L5.75245 6.59277L2.14614 10.1966L1.75682 10.5856L2.14614 10.9747L3.15398 11.9818L3.54275 12.3703L3.93153 11.9818L7.53838 8.37746L11.1452 11.9818L11.534 12.3703L11.9228 11.9818L12.9306 10.9747L13.3199 10.5856L12.9306 10.1966L9.3243 6.59277L12.9306 2.98896Z" fill="#4AC068"/>
                          <path d="M12.9306 2.98896L13.3199 2.59992L12.9306 2.21087L11.9228 1.20373L11.534 0.815225L11.1452 1.20373L7.53838 4.80808L3.93153 1.20373L3.54275 0.815225L3.15398 1.20373L2.14614 2.21087L1.75683 2.59992L2.14614 2.98896L5.75245 6.59277L2.14614 10.1966L1.75682 10.5856L2.14614 10.9747L3.15398 11.9818L3.54275 12.3703L3.93153 11.9818L7.53838 8.37746L11.1452 11.9818L11.534 12.3703L11.9228 11.9818L12.9306 10.9747L13.3199 10.5856L12.9306 10.1966L9.3243 6.59277L12.9306 2.98896Z" stroke="#4AC068" stroke-width="1.1"/>
                          <path d="M12.9306 2.98896L13.3199 2.59992L12.9306 2.21087L11.9228 1.20373L11.534 0.815225L11.1452 1.20373L7.53838 4.80808L3.93153 1.20373L3.54275 0.815225L3.15398 1.20373L2.14614 2.21087L1.75683 2.59992L2.14614 2.98896L5.75245 6.59277L2.14614 10.1966L1.75682 10.5856L2.14614 10.9747L3.15398 11.9818L3.54275 12.3703L3.93153 11.9818L7.53838 8.37746L11.1452 11.9818L11.534 12.3703L11.9228 11.9818L12.9306 10.9747L13.3199 10.5856L12.9306 10.1966L9.3243 6.59277L12.9306 2.98896Z" stroke="#4AC068" stroke-width="1.1"/>
                          </g>
                        </svg>
                      </td>
                      <td class="text-center">
                        <svg width="15" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path opacity="0.75" d="M15.7084 0.592773L7.11149 9.18368L3.92747 6.00186L0.720703 9.20641L7.11149 15.5928L18.9151 3.79732L15.7084 0.592773Z" fill="#FF6440"/>
                        </svg>
                      </td>
                    </tr>
                    <tr>
                      <th class="white-text" scope="row">Coche en Depósito</th>
                      <td class="text-center">
                        <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g opacity="0.75">
                          <path d="M12.9306 2.98896L13.3199 2.59992L12.9306 2.21087L11.9228 1.20373L11.534 0.815225L11.1452 1.20373L7.53838 4.80808L3.93153 1.20373L3.54275 0.815225L3.15398 1.20373L2.14614 2.21087L1.75683 2.59992L2.14614 2.98896L5.75245 6.59277L2.14614 10.1966L1.75682 10.5856L2.14614 10.9747L3.15398 11.9818L3.54275 12.3703L3.93153 11.9818L7.53838 8.37746L11.1452 11.9818L11.534 12.3703L11.9228 11.9818L12.9306 10.9747L13.3199 10.5856L12.9306 10.1966L9.3243 6.59277L12.9306 2.98896Z" fill="#4AC068" fill-opacity="0.996"/>
                          <path d="M12.9306 2.98896L13.3199 2.59992L12.9306 2.21087L11.9228 1.20373L11.534 0.815225L11.1452 1.20373L7.53838 4.80808L3.93153 1.20373L3.54275 0.815225L3.15398 1.20373L2.14614 2.21087L1.75683 2.59992L2.14614 2.98896L5.75245 6.59277L2.14614 10.1966L1.75682 10.5856L2.14614 10.9747L3.15398 11.9818L3.54275 12.3703L3.93153 11.9818L7.53838 8.37746L11.1452 11.9818L11.534 12.3703L11.9228 11.9818L12.9306 10.9747L13.3199 10.5856L12.9306 10.1966L9.3243 6.59277L12.9306 2.98896Z" fill="#4AC068"/>
                          <path d="M12.9306 2.98896L13.3199 2.59992L12.9306 2.21087L11.9228 1.20373L11.534 0.815225L11.1452 1.20373L7.53838 4.80808L3.93153 1.20373L3.54275 0.815225L3.15398 1.20373L2.14614 2.21087L1.75683 2.59992L2.14614 2.98896L5.75245 6.59277L2.14614 10.1966L1.75682 10.5856L2.14614 10.9747L3.15398 11.9818L3.54275 12.3703L3.93153 11.9818L7.53838 8.37746L11.1452 11.9818L11.534 12.3703L11.9228 11.9818L12.9306 10.9747L13.3199 10.5856L12.9306 10.1966L9.3243 6.59277L12.9306 2.98896Z" stroke="#4AC068" stroke-width="1.1"/>
                          <path d="M12.9306 2.98896L13.3199 2.59992L12.9306 2.21087L11.9228 1.20373L11.534 0.815225L11.1452 1.20373L7.53838 4.80808L3.93153 1.20373L3.54275 0.815225L3.15398 1.20373L2.14614 2.21087L1.75683 2.59992L2.14614 2.98896L5.75245 6.59277L2.14614 10.1966L1.75682 10.5856L2.14614 10.9747L3.15398 11.9818L3.54275 12.3703L3.93153 11.9818L7.53838 8.37746L11.1452 11.9818L11.534 12.3703L11.9228 11.9818L12.9306 10.9747L13.3199 10.5856L12.9306 10.1966L9.3243 6.59277L12.9306 2.98896Z" stroke="#4AC068" stroke-width="1.1"/>
                          </g>
                        </svg>
                      </td>
                      <td class="text-center">
                        <svg width="15" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path opacity="0.75" d="M15.7084 0.592773L7.11149 9.18368L3.92747 6.00186L0.720703 9.20641L7.11149 15.5928L18.9151 3.79732L15.7084 0.592773Z" fill="#FF6440"/>
                        </svg>
                      </td>
                    </tr>
                    <tr>
                      <th class="white-text" scope="row">Alquiler por usar tu coche</th>
                      <td class="text-center">
                        <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g opacity="0.75">
                          <path d="M12.9306 2.98896L13.3199 2.59992L12.9306 2.21087L11.9228 1.20373L11.534 0.815225L11.1452 1.20373L7.53838 4.80808L3.93153 1.20373L3.54275 0.815225L3.15398 1.20373L2.14614 2.21087L1.75683 2.59992L2.14614 2.98896L5.75245 6.59277L2.14614 10.1966L1.75682 10.5856L2.14614 10.9747L3.15398 11.9818L3.54275 12.3703L3.93153 11.9818L7.53838 8.37746L11.1452 11.9818L11.534 12.3703L11.9228 11.9818L12.9306 10.9747L13.3199 10.5856L12.9306 10.1966L9.3243 6.59277L12.9306 2.98896Z" fill="#4AC068" fill-opacity="0.996"/>
                          <path d="M12.9306 2.98896L13.3199 2.59992L12.9306 2.21087L11.9228 1.20373L11.534 0.815225L11.1452 1.20373L7.53838 4.80808L3.93153 1.20373L3.54275 0.815225L3.15398 1.20373L2.14614 2.21087L1.75683 2.59992L2.14614 2.98896L5.75245 6.59277L2.14614 10.1966L1.75682 10.5856L2.14614 10.9747L3.15398 11.9818L3.54275 12.3703L3.93153 11.9818L7.53838 8.37746L11.1452 11.9818L11.534 12.3703L11.9228 11.9818L12.9306 10.9747L13.3199 10.5856L12.9306 10.1966L9.3243 6.59277L12.9306 2.98896Z" fill="#4AC068"/>
                          <path d="M12.9306 2.98896L13.3199 2.59992L12.9306 2.21087L11.9228 1.20373L11.534 0.815225L11.1452 1.20373L7.53838 4.80808L3.93153 1.20373L3.54275 0.815225L3.15398 1.20373L2.14614 2.21087L1.75683 2.59992L2.14614 2.98896L5.75245 6.59277L2.14614 10.1966L1.75682 10.5856L2.14614 10.9747L3.15398 11.9818L3.54275 12.3703L3.93153 11.9818L7.53838 8.37746L11.1452 11.9818L11.534 12.3703L11.9228 11.9818L12.9306 10.9747L13.3199 10.5856L12.9306 10.1966L9.3243 6.59277L12.9306 2.98896Z" stroke="#4AC068" stroke-width="1.1"/>
                          <path d="M12.9306 2.98896L13.3199 2.59992L12.9306 2.21087L11.9228 1.20373L11.534 0.815225L11.1452 1.20373L7.53838 4.80808L3.93153 1.20373L3.54275 0.815225L3.15398 1.20373L2.14614 2.21087L1.75683 2.59992L2.14614 2.98896L5.75245 6.59277L2.14614 10.1966L1.75682 10.5856L2.14614 10.9747L3.15398 11.9818L3.54275 12.3703L3.93153 11.9818L7.53838 8.37746L11.1452 11.9818L11.534 12.3703L11.9228 11.9818L12.9306 10.9747L13.3199 10.5856L12.9306 10.1966L9.3243 6.59277L12.9306 2.98896Z" stroke="#4AC068" stroke-width="1.1"/>
                          </g>
                        </svg>
                      </td>
                      <td class="text-center">
                        <svg width="15" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path opacity="0.75" d="M15.7084 0.592773L7.11149 9.18368L3.92747 6.00186L0.720703 9.20641L7.11149 15.5928L18.9151 3.79732L15.7084 0.592773Z" fill="#FF6440"/>
                        </svg>
                      </td>
                    </tr>
                    <tr>
                      <th class="white-text" scope="row">Acepta RAI o ASNEF</th>
                      <td class="text-center">
                        <svg width="15" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path opacity="0.75" d="M15.7084 0.592773L7.11149 9.18368L3.92747 6.00186L0.720703 9.20641L7.11149 15.5928L18.9151 3.79732L15.7084 0.592773Z" fill="#4AC068"/>
                        </svg>
                      </td>
                      <td class="text-center">
                        <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g opacity="0.75">
                          <path d="M12.9306 2.98896L13.3199 2.59992L12.9306 2.21087L11.9228 1.20373L11.534 0.815225L11.1452 1.20373L7.53838 4.80808L3.93153 1.20373L3.54275 0.815225L3.15398 1.20373L2.14614 2.21087L1.75683 2.59992L2.14614 2.98896L5.75245 6.59277L2.14614 10.1966L1.75682 10.5856L2.14614 10.9747L3.15398 11.9818L3.54275 12.3703L3.93153 11.9818L7.53838 8.37746L11.1452 11.9818L11.534 12.3703L11.9228 11.9818L12.9306 10.9747L13.3199 10.5856L12.9306 10.1966L9.3243 6.59277L12.9306 2.98896Z" fill="#FA0000" fill-opacity="0.996"/>
                          <path d="M12.9306 2.98896L13.3199 2.59992L12.9306 2.21087L11.9228 1.20373L11.534 0.815225L11.1452 1.20373L7.53838 4.80808L3.93153 1.20373L3.54275 0.815225L3.15398 1.20373L2.14614 2.21087L1.75683 2.59992L2.14614 2.98896L5.75245 6.59277L2.14614 10.1966L1.75682 10.5856L2.14614 10.9747L3.15398 11.9818L3.54275 12.3703L3.93153 11.9818L7.53838 8.37746L11.1452 11.9818L11.534 12.3703L11.9228 11.9818L12.9306 10.9747L13.3199 10.5856L12.9306 10.1966L9.3243 6.59277L12.9306 2.98896Z" fill="#FF6440"/>
                          <path d="M12.9306 2.98896L13.3199 2.59992L12.9306 2.21087L11.9228 1.20373L11.534 0.815225L11.1452 1.20373L7.53838 4.80808L3.93153 1.20373L3.54275 0.815225L3.15398 1.20373L2.14614 2.21087L1.75683 2.59992L2.14614 2.98896L5.75245 6.59277L2.14614 10.1966L1.75682 10.5856L2.14614 10.9747L3.15398 11.9818L3.54275 12.3703L3.93153 11.9818L7.53838 8.37746L11.1452 11.9818L11.534 12.3703L11.9228 11.9818L12.9306 10.9747L13.3199 10.5856L12.9306 10.1966L9.3243 6.59277L12.9306 2.98896Z" stroke="#FA0000" stroke-width="1.1"/>
                          <path d="M12.9306 2.98896L13.3199 2.59992L12.9306 2.21087L11.9228 1.20373L11.534 0.815225L11.1452 1.20373L7.53838 4.80808L3.93153 1.20373L3.54275 0.815225L3.15398 1.20373L2.14614 2.21087L1.75683 2.59992L2.14614 2.98896L5.75245 6.59277L2.14614 10.1966L1.75682 10.5856L2.14614 10.9747L3.15398 11.9818L3.54275 12.3703L3.93153 11.9818L7.53838 8.37746L11.1452 11.9818L11.534 12.3703L11.9228 11.9818L12.9306 10.9747L13.3199 10.5856L12.9306 10.1966L9.3243 6.59277L12.9306 2.98896Z" stroke="#FF6440" stroke-width="1.1"/>
                          </g>
                        </svg>
                      </td>
                    </tr>
                    <tr>
                      <th class="white-text" scope="row">Importe máx. por tu coche</th>
                      <td class="text-center">
                        Hasta <strong>70%</strong> del valor del vehículo
                      </td>
                      <td class="text-center">
                        Hasta 50% del valor del vehículo
                      </td>
                    </tr>
                    <tr>
                      <th class="white-text" scope="row">Antigüedad coche</th>
                      <td class="text-center">
                        Hasta 14 años
                      </td>
                      <td class="text-center">
                        Hasta 10 años
                      </td>
                    </tr>
                    <tr>
                      <th class="white-text" scope="row">Pack Servicios incluido</th>
                      <td class="text-center">
                        <svg width="15" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path opacity="0.75" d="M15.7084 0.592773L7.11149 9.18368L3.92747 6.00186L0.720703 9.20641L7.11149 15.5928L18.9151 3.79732L15.7084 0.592773Z" fill="#4AC068"/>
                        </svg>
                      </td>
                      <td class="text-center">
                        <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g opacity="0.75">
                          <path d="M12.9306 2.98896L13.3199 2.59992L12.9306 2.21087L11.9228 1.20373L11.534 0.815225L11.1452 1.20373L7.53838 4.80808L3.93153 1.20373L3.54275 0.815225L3.15398 1.20373L2.14614 2.21087L1.75683 2.59992L2.14614 2.98896L5.75245 6.59277L2.14614 10.1966L1.75682 10.5856L2.14614 10.9747L3.15398 11.9818L3.54275 12.3703L3.93153 11.9818L7.53838 8.37746L11.1452 11.9818L11.534 12.3703L11.9228 11.9818L12.9306 10.9747L13.3199 10.5856L12.9306 10.1966L9.3243 6.59277L12.9306 2.98896Z" fill="#FA0000" fill-opacity="0.996"/>
                          <path d="M12.9306 2.98896L13.3199 2.59992L12.9306 2.21087L11.9228 1.20373L11.534 0.815225L11.1452 1.20373L7.53838 4.80808L3.93153 1.20373L3.54275 0.815225L3.15398 1.20373L2.14614 2.21087L1.75683 2.59992L2.14614 2.98896L5.75245 6.59277L2.14614 10.1966L1.75682 10.5856L2.14614 10.9747L3.15398 11.9818L3.54275 12.3703L3.93153 11.9818L7.53838 8.37746L11.1452 11.9818L11.534 12.3703L11.9228 11.9818L12.9306 10.9747L13.3199 10.5856L12.9306 10.1966L9.3243 6.59277L12.9306 2.98896Z" fill="#FF6440"/>
                          <path d="M12.9306 2.98896L13.3199 2.59992L12.9306 2.21087L11.9228 1.20373L11.534 0.815225L11.1452 1.20373L7.53838 4.80808L3.93153 1.20373L3.54275 0.815225L3.15398 1.20373L2.14614 2.21087L1.75683 2.59992L2.14614 2.98896L5.75245 6.59277L2.14614 10.1966L1.75682 10.5856L2.14614 10.9747L3.15398 11.9818L3.54275 12.3703L3.93153 11.9818L7.53838 8.37746L11.1452 11.9818L11.534 12.3703L11.9228 11.9818L12.9306 10.9747L13.3199 10.5856L12.9306 10.1966L9.3243 6.59277L12.9306 2.98896Z" stroke="#FA0000" stroke-width="1.1"/>
                          <path d="M12.9306 2.98896L13.3199 2.59992L12.9306 2.21087L11.9228 1.20373L11.534 0.815225L11.1452 1.20373L7.53838 4.80808L3.93153 1.20373L3.54275 0.815225L3.15398 1.20373L2.14614 2.21087L1.75683 2.59992L2.14614 2.98896L5.75245 6.59277L2.14614 10.1966L1.75682 10.5856L2.14614 10.9747L3.15398 11.9818L3.54275 12.3703L3.93153 11.9818L7.53838 8.37746L11.1452 11.9818L11.534 12.3703L11.9228 11.9818L12.9306 10.9747L13.3199 10.5856L12.9306 10.1966L9.3243 6.59277L12.9306 2.98896Z" stroke="#FF6440" stroke-width="1.1"/>
                          </g>
                        </svg>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-3"></div>
          </div>
          <div class="col text-center pt-5">
              <button class="btn-orange" @click="goTo('/credito-coche','#packServicios')" >Ver pack de servicios</button>
          </div>
      </div>
</div>
    <!--  -->

</template>

<script>
export default {
  name: "Section3",
  methods: {
    goTo(route, hash) {
      this.$router.push({path: route, hash: hash});
    }
  }
};
</script>

<style scoped lang="scss">
.bg-img-azul {
  width: 100%;
  background-image: url("../../assets/img/section3_bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0;
  padding-bottom: 120px;
  z-index: -1;
}

.title-h1 {
  color: #fff;
}

.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #fff;
    border-spacing: unset;
    border: 0px solid #fff !important;
  }

  .table thead {
    border: 0px solid #fff;
  }

  .table tbody th {
    text-align: left;
  }
  
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-bottom: 1px solid #fff;
    background-color: transparent !important;
  }
  
  .table thead th {
    vertical-align: bottom;
    border: none;
  }

  tr {
    border: 0px solid #fff !important;
  }

  .table tbody + tbody {
    border-top: 0px solid #fff;
  }
  
  .table-sm th,
  .table-sm td {
    padding: 0.3rem;
  }

  .green-text {
    font-family: 'Montserrat';
    font-display: swap;
    font-style: normal;
    font-weight: bold;
    color: #4AC068;
    opacity: 0.75;
  }

  .red-text {
    font-family: 'Montserrat';
    font-display: swap;
    font-style: normal;
    font-weight: bold;
    color: #FF6440;
    opacity: 0.75;
  }

  .white-text {
    font-family: 'Montserrat';
    font-display: swap;
    font-style: normal;
    font-weight: 500;
    color: #FFFFFF;
  }

  // Resposive para pantallas a partir de tamaño medio a pequeño

@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

@include media-breakpoint-down(md) {
  .title-h1 {
    font-size: 1.25rem;
  }
}

@include media-breakpoint-between(lg, xl) {


}

  
</style>
