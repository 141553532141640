<template>
  <div>
    <metainfo>
      <template v-slot:title="{ content }">{{ content ? `${content} | Ibancar` : `Ibancar` }}</template>
      <meta name="theme-color" />
      <link rel="apple-touch-icon" href="/transparent.png" />
    </metainfo>
    <span class="screen-darken"></span>
    <header v-if="this.$route.name != 'Investor'">
      <NavBar2 @clicked="clicked" />
      <WhatssapButton />
    </header>
    <!-- Header especial para inversores -->
    <header v-else>
      <NavBarInvestor />
    </header>
    <section>
      <div>
        <router-view v-slot="{ Component, route }" :key="$route.path">
          <transition :name="route.meta.transition ? route.meta.transition : 'fade'">
            <component :is="Component" />
          </transition>
        </router-view>
      </div>
    </section>
    <section>
      <Foot v-if="this.$route.name != 'Investor'" />
      <FooterFormulario/>
      <FootInvestor v-if="this.$route.name == 'Investor' && !rutasFormulario" />
    </section>
  </div>
</template>
<script>
// import 'bootstrap/dist/js/bootstrap.min.js';
import NavBar2 from "./components/NavBar2.vue";
import NavBarInvestor from "./components/NavBarInvestor.vue";
import Foot from "./components/Foot.vue";
import FooterFormulario from "./components/FooterFormulario.vue";
import FootInvestor from "./components/FootInvestor.vue";
import WhatssapButton from "./components/WhatssapButton.vue";
import { useMeta } from "vue-meta";
import { useRoute } from "vue-router";
import { ref, watch } from "vue";
import { mapState } from "vuex";
import store from "@/store";

export default {
  components: {
    NavBar2,
    NavBarInvestor,
    Foot,
    FootInvestor,
    WhatssapButton,

    FooterFormulario,
  },
  store,
  computed: mapState({
    afiliado: (state) => state.afiliado,
    api_url: (state) => state.api_url,
    dominio: (state) => state.dominio
  }),
  setup() {
    const route = useRoute();
    const rutasFormulario = ref();
    watch(() => (route.path.includes("/formulario/") ? (rutasFormulario.value = true) : (rutasFormulario.value = false)));
    useMeta({
      title: "",
      description: "",
      htmlAttrs: { lang: "es", amp: true },
    });
    return {
      route,
      rutasFormulario,
    };
  },
  data() {
    return {};
  },
  methods: {
    darken_screen(yesno) {
      let screen_darken = document.querySelector(".screen-darken");
      if (yesno == true) {
        screen_darken.classList.add("active");
      } else if (yesno == false) {
        screen_darken ? screen_darken.classList.remove("active") : "";
      }
    },
    close_offcanvas() {
      let mobile_canvas = document.querySelector(".mobile-offcanvas.show");
      this.darken_screen(false);
      mobile_canvas ? mobile_canvas.classList.remove("show") : "";
      document.body.classList.remove("offcanvas-active");
    },
    show_offcanvas(offcanvas_id) {
      this.darken_screen(true);
      let offcanvas = document.getElementById(offcanvas_id);
      offcanvas ? offcanvas.classList.add("show") : "";
      document.body.classList.add("offcanvas-active");
    },
    clicked() {
      let data_trigger = document.querySelectorAll("[data-trigger]");

      data_trigger.forEach((everyelement) => {
        let offcanvas_id = everyelement.getAttribute("data-trigger");
        this.show_offcanvas(offcanvas_id);
      });

      let screen_darken = document.querySelector(".screen-darken");
      screen_darken
        ? screen_darken.addEventListener("click", (e) => {
            e.preventDefault();
            this.close_offcanvas();
          })
        : "";
    },
  },
  mounted: function () {
    if (localStorage.getItem("url_origen") == undefined || localStorage.getItem("url_origen") == null) {
      localStorage.setItem("url_origen", window.location.href);
    }
    let uri = window.location.search.substring(1);
    let params = new URLSearchParams(uri);
    if (params.get("aff")) { //#NF con esto guardamos el codigo de afiliado de los clientes que son redireccionados, esto nos servira para mandarlo via google tag
      if (this.afiliado == null) store.commit("setAfiliado", params.get("aff"));
    } else if (this.afiliado == null) store.commit("setAfiliado", "04");

  },
};
</script>

<style lang="scss">
#app {
  min-width: 320px;
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // color: #2c3e50;
  font-display: swap;
  overflow-x: hidden;
}
body.offcanvas-active {
  overflow: hidden;
}

// Clases para controlar el navbar y oscurecer la pantalla cuando se entra en modo mobile

.offcanvas-header {
  display: none;
}

.screen-darken {
  height: 100%;
  width: 0%;
  z-index: 30;
  position: fixed;
  top: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(34, 34, 34, 0.6);
  transition: opacity 0.2s linear, visibility 0.2s, width 2s ease-in;
}

.screen-darken.active {
  z-index: 10;
  transition: opacity 0.3s ease, width 0s;
  opacity: 1;
  width: 100%;
  visibility: visible;
}
</style>
