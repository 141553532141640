<template>
<div>
  <NotFound01 />
</div>
</template>

<script>
import { useMeta } from 'vue-meta'
import NotFound01 from '../components/NotFound/NotFound01.vue'

export default {
  name: 'NotFound',
  components: {
    NotFound01
    },
  setup() {

  useMeta({
    title: '404 NOT FOUND',
    meta:[]
  })
  
  }
}
</script>

<style>

</style>