<template>

    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col text-center pt-4">
          <h2 class="title-h1">Quienes Somos</h2>
        </div>
      </div>
      <div class="row justify-content-center align-content-end">
          <div class="card-deck">
            <div class="card mb-5">
              <div class="card-body">
                <h2 class="card-title"><a href="/quienes-somos/#noticia1" ><strong>Ibancar, el mejor préstamo con aval de coche.</strong> </a></h2>
                <p class="card-text"><a href="/quienes-somos/#noticia1">Somos la única empresa que no cambia la titularidad del coche, es decir, el coche es tuyo y siempre será tuyo. </a></p>
              </div>
              <div class="card-footer d-flex justify-content-center pt-3">
                <a href="/quienes-somos/#noticia1">
                  <img width="331" height="220" class="img-fluid" src="../../assets/img/quienessomosf1.webp" alt="Hombre respondiendo a clientes">
                </a>
              </div>
            </div>
            <div class="card mb-5">
              <div class="card-body ">
                <h2 class="card-title"><a href="/quienes-somos/#noticia2"><strong>Miles de clientes satisfechos en los últimos 5 años.</strong></a></h2>
                <p class="card-text"><a href="/quienes-somos/#noticia2">Ya son más de 8 años ayudando a personas a conseguir dinero.</a></p>
              </div>
              <div class="card-footer d-flex justify-content-center pt-3">
                <a href="/quienes-somos/#noticia2">
                  <img width="331" height="220" class="img-fluid" src="../../assets/img/quienessomosf2.webp" alt="Personas cruzando paso de peatones">
                </a>
              </div>
            </div>
          </div>
      </div>
      <div class="row">
        <div class="col text-center">
            <router-link class="btn btn-orange" to="/">Solicitar préstamo</router-link>
        </div>
      </div>
    </div>
  
</template>

<script>
export default {
  name: "Section4",

};
</script>

<style scoped lang="scss">
a {
  color: #FFA500;
  &:focus {
    color: #FFA500;
  }
  &:active {
    color: #FFA500;
  }
}
p {
  a {
    color: #333;
    text-decoration: none;
    &:focus {
      color: #333;
    }
    &:active {
      color: #333;
    }
  }
}
.card {
  border: none
}
.card-header {
  padding: 0;
  background-color: transparent;
  border: none
}
.card-body {
  padding: 0;
  text-align: left;
  font-size: 26px;
}
.card-footer {
  background-color: #fff;
  border: none;
  padding: 0;
}
.card-title {
  text-align: left;
  font-family: 'Montserrat';
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  color: #FFA500;
}
  // Resposive para pantallas a partir de tamaño medio a pequeño

@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

@include media-breakpoint-down(md) {
  .title-h1 {
    font-size: 1.5rem;
    font-weight: bold;
  }
  .card {
    padding: 1.5%;
  }
  .card-title {
    font-size: 1.3rem;
  }
  p {
    font-size: 1.1rem;
  }


}

@include media-breakpoint-between(md, lg) {
  .card {
    padding: 7%;
  }
  p {
    font-size: 20px;
  }
  .padding {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .img-fluid {
    width: 410px;
  }
}

@include media-breakpoint-between(xl, xxl) {
  .card {
    padding: 7%;
  }
  p {
    font-size: 20px;
  }
  .img-fluid {
    width: 600px;
  }
}
</style>
