<template>
    <div class="container-fluid pt-5">
        <div class="row">
            <div class="col">
                <h2 class="title-h1 text-center">
                    Lo que opinan de nosotros
                </h2>
            </div>
        </div>
    </div>
    <div id="opiniones" class="d-flex flex-row justify-content-center p-3">
            <div id="mobile" class="carousel slide" data-touch="true" data-ride="carousel">
                <ol class="carousel-indicators">
                    <li data-target="#opiniones" data-slide-to="0" class="active"></li>
                    <li data-target="#opiniones" data-slide-to="1"></li>
                    <li data-target="#opiniones" data-slide-to="2"></li>
                </ol>
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <TarjetaComentarios :title="comentarios[0].title" :comentario="comentarios[0].comentario" :imgUrl="require('@/assets/img/avatar1.webp')" :stars="true"></TarjetaComentarios>
                    </div>
                    <div class="carousel-item">
                        <TarjetaComentarios :title="comentarios[1].title" :comentario="comentarios[1].comentario" :imgUrl="require('@/assets/img/avatar2.webp')" :stars="true"></TarjetaComentarios>
                    </div>
                    <div class="carousel-item">
                        <TarjetaComentarios :title="comentarios[2].title" :comentario="comentarios[2].comentario" :imgUrl="require('@/assets/img/avatar3.webp')" :stars="true"></TarjetaComentarios>
                    </div>
                </div>
                <a class="carousel-control-prev" href="#opiniones" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#opiniones" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
            </div>
            <div id="desktop" class="d-flex flex-row m-5">
                    <TarjetaComentarios :title="comentarios[0].title" :comentario="comentarios[0].comentario" :imgUrl="require('@/assets/img/avatar1.webp')" :stars="true"></TarjetaComentarios>
                    <TarjetaComentarios :title="comentarios[1].title" :comentario="comentarios[1].comentario" :imgUrl="require('@/assets/img/avatar2.webp')" :stars="true"></TarjetaComentarios>
                    <TarjetaComentarios :title="comentarios[2].title" :comentario="comentarios[2].comentario" :imgUrl="require('@/assets/img/avatar3.webp')" :stars="true"></TarjetaComentarios>
            </div>
<!--  -->

    </div>
    <!--  -->
        <h2 class="title-h1 text-center">Miles de clientes satisfechos</h2>
        <div id="clientes" class="row justify-content-center p-2">
            <div class="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center p-2">
                <h3 class="h3-text-verde">+90.000</h3>
                <h4 class="h4-text-verde">Clientes gestionados</h4>
            </div>
            <div class="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center p-2">
                <h3 class="h3-text-azul">+15.000.000€</h3>
                <h4 class="h4-text-azul">En préstamos otorgados</h4>
            </div>
            <div class="col-xl-3 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center p-2">
                <h3 class="h3-text-salmon">+1.500</h3>
                <h4 class="h4-text-salmon">Solicitudes diarias</h4>
            </div>
        </div>
        <!--  -->
        <div id="desktop" class="background-gray pb-3">
            <div class="pt-3 pb-3 mt-5 mb-5 background-gray text-center">
                <span class="title-h1 text-center">Nuestra presencia en prensa</span>
            </div>
            <div id="prensa" class="d-flex flex-row justify-content-center text-wrap m-4">
                        <a href="#"><img width="198" height="47" class="img-fluid mr-5" src="../../assets/img/logo-libreinversion.webp" alt="libre-inversion"></a>
                        <a href="https://finance.yahoo.com/news/spanish-company-ibancar-raises-1-080000459.html?guccounter=1" rel="nofollow noopener" target="_blank" ><img width="198" height="47" id="yahoo" class="img-fluid mr-5" src="../../assets/img/logo-yahoo.webp" alt="yahoo!"></a>
                        <a href="https://www.prnewswire.com/es/comunicados-de-prensa/la-empresa-espanola-ibancar-completa-su-primera-ronda-de-financiacion-de-capital-externo-levantando-1-65-millones-de-euros-808272010.html" rel="nofollow noopener" target="_blank"><img width="198" height="47" class="img-fluid mr-5" src="../../assets/img/logo-cision.webp" alt="cision"></a>
                        <a href="https://www.europapress.es/comunicados/internacional-00907/noticia-comunicado-empresa-espanola-ibancar-completa-primera-ronda-financiacion-capital-externo-levantando-165-millones-20201124090107.html" rel="nofollow noopener" target="_blank"><img width="198" height="47" class="img-fluid mr-5" src="../../assets/img/logo-europapress.webp" alt="europapress"></a>
                </div>
            <!--  -->
            <span class="title-h1 d-flex flex-row justify-content-center pt-5 pb-5">Nuestros partners</span>
            <div id="socios" class="d-flex flex-row justify-content-center text-wrap m-4">
                        <a href="https://www.iuvo-group.com/es/originator-ibancar/" rel="nofollow noopener" target="_blank"><img width="150" height="50" class="img-fluid mr-5" src="../../assets/img/logo-iuvo.webp" alt="iuvo"></a>
                        <a href="https://www.midas.es/" rel="nofollow noopener" target="_blank"><img width="150" height="50" class="img-fluid mr-5" src="../../assets/img/logo-midas.webp" alt="midas"></a>
                        <a href="http://www.asnef.com/" rel="nofollow noopener" target="_blank"><img width="150" height="50" class="img-fluid mr-5" src="../../assets/img/logo-asnef.webp" alt="asnef"></a>
                        <a href="https://www.mrw.es/" rel="nofollow noopener" target="blank"><img width="150" height="50" class="img-fluid mr-5" src="../../assets/img/logo-mrw.webp" alt="mrw"></a>
                        <a href="https://www.ganvam.es/" rel="nofollow noopener" target="_blank"><img width="150" height="50" class="img-fluid mr-5" src="../../assets/img/logo-ganvam.webp" alt="ganvam"></a>
                        <a href="https://www.bdo.es/es-es/home" rel="nofollow noopener" target="_blank"><img width="150" height="50" class="img-fluid mr-5" src="../../assets/img/BDO_logo-198_47.webp" alt="bdo"></a>
                        <a href="https://www.netsuite.com/" rel="nofollow noopener" target="_blank"><img width="150" height="50" class="img-fluid mr-5" src="../../assets/img/oracle-netsuite-logo-198_47.webp" alt="oracle"></a>
                        <a href="https://kineox.com/" rel="nofollow noopener" target="_blank"><img width="150" height="50" class="img-fluid mr-5" src="../../assets/img/logo-kineox.webp" alt="oracle"></a>
                        <a href="https://www.transferenciabancariapsd2.com/" rel="nofollow noopener" target="_blank"><img width="150" height="50" class="img-fluid mr-5" src="../../assets/img/logo-psd2.webp" alt="oracle"></a>
            </div>
        </div>
        <!--  -->
        <div id="carousel">
        <div id="mobile" class="carousel slide text-center" data-touch="true" data-ride="carousel">
            <span class="title-h1 text-center pt-5 pb-2">Nuestra presencia en prensa</span>
                <div class="carousel-inner">
                    <div class="carousel-item active">
                      <div class="card p-2">
                          <img width="198" height="47" src="../../assets/img/logo-libreinversion.webp" class="card-img-top" alt="ibancar_libreinversion">
                      </div>
                    </div>
                    <div class="carousel-item">
                      <div class="card p-2">
                          <img width="198" height="47" src="../../assets/img/logo-yahoo.webp" class="card-img-top" alt="ibancar_yahoo">
                      </div>
                    </div>
                    <div class="carousel-item">
                      <div class="card p-2">
                          <img width="198" height="47" src="../../assets/img/logo-cision.webp" class="card-img-top" alt="ibancar_cision">
                      </div>
                    </div>
                    <div class="carousel-item">
                      <div class="card p-2">
                          <img width="198" height="47" src="../../assets/img/logo-europapress.webp" class="card-img-top" alt="ibancar_europapress">
                      </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="carousel">
        <div id="mobile" class="carousel slide text-center" data-touch="true" data-ride="carousel">
            <span class="title-h1 text-center pt-5 pb-2">Nuestros partners</span>
                <div class="carousel-inner">
                    <div class="carousel-item active">
                      <div class="card p-2">
                          <img width="150" height="50" src="../../assets/img/logo-iuvo.webp" class="card-img-top" alt="ibancar_iuvo">
                      </div>
                    </div>
                    <div class="carousel-item">
                      <div class="card p-2">
                          <img width="150" height="50" src="../../assets/img/logo-midas.webp" class="card-img-top" alt="ibancar_midas">
                      </div>
                    </div>
                    <div class="carousel-item">
                      <div class="card p-2">
                          <img width="150" height="50" src="../../assets/img/logo-asnef.webp" class="card-img-top" alt="ibancar_asnef">
                      </div>
                    </div>
                    <div class="carousel-item">
                      <div class="card p-2">
                          <img width="150" height="50" src="../../assets/img/logo-mrw.webp" class="card-img-top" alt="ibancar_mrw">
                      </div>
                    </div>
                    <div class="carousel-item">
                      <div class="card p-2">
                          <img width="150" height="50" src="../../assets/img/logo-ganvam.webp" class="card-img-top" alt="ibancar_ganvam">
                      </div>
                    </div>
                    <div class="carousel-item">
                      <div class="card p-2">
                          <img width="150" height="50" src="../../assets/img/BDO_logo-198_47.webp" class="card-img-top" alt="ibancar_bdo">
                      </div>
                    </div>
                    <div class="carousel-item">
                      <div class="card p-2">
                          <img width="150" height="50" src="../../assets/img/oracle-netsuite-logo-198_47.webp" class="card-img-top" alt="ibancar_oracle">
                      </div>
                    </div>
                    <div class="carousel-item">
                      <div class="card p-2">
                          <img width="150" height="50" src="../../assets/img/logo-kineox.webp" class="card-img-top" alt="kineox">
                      </div>
                    </div>
                    <div class="carousel-item">
                      <div class="card p-2">
                          <img width="150" height="50" src="../../assets/img/logo-psd2.webp" class="card-img-top" alt="psd2">
                      </div>
                    </div>
                </div>
            </div>
        </div>
        



</template>

<script>
import TarjetaComentarios from './tarjeta-comentarios.vue';

export default {
  name: "Section5",
  components: {
    TarjetaComentarios
  },
  data() {
      return {
        settings: {
          itemsToShow: 1,
          snapAlign: "center",
          initialSlide: 0
        },
        comentarios: [
            {id: 0, title: "María Perales", comentario: "Gestión eficaz y rápida. Amabilidad y claridad de los agentes, trato muy bueno de atención al cliente. Los recomendaría para salir de un apuro.", imagen: "require('@/assets/img/avatar1.webp')"},
            {id: 1, title: "Daniel Herrero", comentario: "Estoy encantado. Un servicio de atención al cliente excelente. Amortizo capital e interés al mismo tiempo, y la cancelación es fácil y barata.", imagen: "avatar2.webp"},
            {id: 2, title: "Marina Figueroa", comentario: "Otras companía te cobran un renting por conducir tu coche, pero con Ibancar no hizo falta. Me gestionaron todo por whatsapp y no tuve que pagar una cuota extra al final.", imagen: "avatar3.webp"}
        ],
        prensa: [
            {imagen: "logo-libreinversion.webp"},
            {imagen: "logo-libreinversion.webp"},
            {imagen: "logo-libreinversion.webp"},
        ]
      }
      
  },
  mounted() {
  },
  methods: {

  }
};
</script>

<style scoped lang="scss">
/* purgecss ignore */
#opiniones {
    .card {
        z-index: -1;
        width: 20rem;
        height: 16rem;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
}
#desktop {
    .card {
        z-index: -1;
        width: 19rem;
        height: 16rem;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
}
#prensa {
    .card {
        z-index: -1;
        box-shadow: none;
        border: none;
    }
}
#socios {
    .card {
        z-index: -1;
        box-shadow: none;
        border: none;
    }
}
.h4-text-verde {
    font-weight: bold;
    color: #00C4A7;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
}
.h3-text-verde {
    font-weight: bold;
    color: #00C4A7;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
}
.h4-text-azul {
    font-weight: bold;
    color: #004AAD;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
}
.h3-text-azul {
    font-weight: bold;
    color: #004AAD;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
}
.h4-text-salmon {
    font-weight: bold;
    color: #FFB97F;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
}
.h3-text-salmon {
    font-weight: bold;
    color: #FFB97F;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
}
.background-gray {
    background-color: #f8f8f8;
}

  // Resposive para pantallas a partir de tamaño medio a pequeño

@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

@include media-breakpoint-between(xs, sm) {
  .title-h1 {
    font-size: 1.5rem;
    font-weight: bold;
  }
  #opiniones {
      .card {
        width: 17rem;
        height: 15rem;
        font-size: 1.8vh;
        font-size: 0.85rem;
      }
  }
    .padding {
        padding: 1.5rem;
    }
  #desktop {
    display: none !important;
  }
    img {
        filter: grayscale(0);
    }
    #carousel {
        #mobile {
            .card {
                border: none;
                margin-left: 12.5%;
                margin-right: 12.5%;
                height: auto;
                width: 75%;
            }
        }
    }
    .h4-text-verde {
        font-size: 18.5px;
    }
    .h3-text-verde {
        font-size: 50px;
    }
    .h4-text-azul {
        font-size: 18.5px;
    }
    .h3-text-azul {
        font-size: 50px;
    }
    .h4-text-salmon {
        font-size: 18.5px;
    }
    .h3-text-salmon {
        font-size: 50px;
    }
    
}

@include media-breakpoint-between(md, lg) {
  .title-h1 {
    font-size: 1.5rem;
    font-weight: bold;
  }
  #desktop {
      display: none !important;
  }
  .padding {
      padding: 0;
  }
  #clientes {
      padding-left: 7rem;
      padding-right: 7rem;
  }
  #prensa {
      img {
          filter: grayscale(100);
      }
      img:hover {
          filter: grayscale(0);
      }
  }
  #socios {
      img {
          filter: grayscale(100);
      }
      img:hover {
          filter: grayscale(0);
      }
  }
    #carousel {
        #mobile {
            .card {
                border: none;
                margin-left: 25%;
                margin-right: 25%;
                height: auto;
                width: 50%;
                img {
                    filter: grayscale(100);
                }
            }
        }
    }
    .h4-text-verde {
        font-size: 1.3rem;
        padding-bottom: 25px;
    }
    .h3-text-verde {
        font-size: 60px;
    }
    .h4-text-azul {
        font-size: 1.3rem;
        padding-bottom: 25px;
    }
    .h3-text-azul {
        font-size: 60px;
    }
    .h4-text-salmon {
        font-size: 1.3rem;
    }
    .h3-text-salmon {
        font-size: 60px;
    }
}

@include media-breakpoint-between(xl, xxl) {
    #mobile {
        display: none !important;
    }
    .padding {
        padding: 2.5rem;
    }
    #clientes {
        padding-left: 7rem;
        padding-right: 7rem;
    }
    #prensa {
        img {
            filter: grayscale(100);
        }
        img:hover {
            filter: grayscale(0);
        }
    }
    #socios {
        img {
            filter: grayscale(100);
        }
        img:hover {
            filter: grayscale(0);
        }
    }
    .h4-text-verde {
        font-size: 22.5px;
    }
    .h3-text-verde {
        font-size: 50px;
    }
    .h4-text-azul {
        font-size: 22.5px;
    }
    .h3-text-azul {
        font-size: 50px;
    }
    .h4-text-salmon {
        font-size: 22.5px;
    }
    .h3-text-salmon {
        font-size: 50px;
    }
}

</style>
