<template>
    <div class="card bg-gray m-3">
        <div class="pl-3">
            <div class="d-flex align-items-center pb-3">
                <div class="pt-3 pr-2">
                    <img width="37" height="37" :src="imgUrl" alt="ibancar" >
                </div>
                <div class="d-flex flex-column pt-4 pl-1">
                    <p class="card-title title-style">{{title ? title: ''}}</p>
                    <svg v-if="stars" width="97" height="16" viewBox="0 0 97 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M29.1805 2.2534L30.6785 5.68349L30.8937 6.17614L31.4297 6.21706L35.2143 6.50603L32.3648 8.83075L31.9247 9.18974L32.0627 9.74062L32.948 13.2751L29.63 11.345L29.1805 11.0836L28.731 11.345L25.4131 13.2751L26.2984 9.74062L26.4363 9.18974L25.9963 8.83075L23.1467 6.50603L26.9314 6.21706L27.4674 6.17614L27.6825 5.68349L29.1805 2.2534Z" fill="#F1C644" stroke="#F1C644" stroke-width="1.78788"/>
                        <path d="M49.0858 2.2534L50.5838 5.68349L50.7989 6.17614L51.335 6.21706L55.1196 6.50603L52.27 8.83075L51.83 9.18974L51.968 9.74062L52.8532 13.2751L49.5353 11.345L49.0858 11.0836L48.6363 11.345L45.3184 13.2751L46.2036 9.74062L46.3416 9.18974L45.9016 8.83075L43.052 6.50603L46.8366 6.21706L47.3727 6.17614L47.5878 5.68349L49.0858 2.2534Z" fill="#F1C644" stroke="#F1C644" stroke-width="1.78788"/>
                        <path d="M9.27525 2.2534L10.7732 5.68349L10.9884 6.17614L11.5244 6.21706L15.3091 6.50603L12.4595 8.83075L12.0195 9.18974L12.1574 9.74062L13.0427 13.2751L9.72475 11.345L9.27525 11.0836L8.82576 11.345L5.50782 13.2751L6.39308 9.74062L6.53106 9.18974L6.09102 8.83075L3.24145 6.50603L7.0261 6.21706L7.56212 6.17614L7.77727 5.68349L9.27525 2.2534Z" fill="#F1C644" stroke="#F1C644" stroke-width="1.78788"/>
                        <path d="M68.5739 2.34756L69.9554 5.66902L70.1651 6.17317L70.7093 6.2168L74.2951 6.50427L71.5631 8.84452L71.1485 9.19973L71.2751 9.73085L72.1098 13.23L69.0399 11.3549L68.5739 11.0703L68.1079 11.3549L65.038 13.23L65.8727 9.73085L65.9994 9.19973L65.5847 8.84452L62.8527 6.50427L66.4385 6.2168L66.9827 6.17317L67.1924 5.66902L68.5739 2.34756Z" fill="#F1C644" stroke="#F1C644" stroke-width="1.78788"/>
                        <path d="M88.0619 2.2534L89.5599 5.68349L89.775 6.17614L90.311 6.21706L94.0957 6.50603L91.2461 8.83075L90.8061 9.18974L90.944 9.74062L91.8293 13.2751L88.5114 11.345L88.0619 11.0836L87.6124 11.345L84.2944 13.2751L85.1797 9.74062L85.3177 9.18974L84.8776 8.83075L82.0281 6.50603L85.8127 6.21706L86.3487 6.17614L86.5639 5.68349L88.0619 2.2534Z" fill="#F1C644" stroke="#F1C644" stroke-width="1.78788"/>
                    </svg>
                </div>
            </div>
        </div>
        <div class="card-body pt-0">
            <!-- <h5 class="card-title">Card title</h5> -->
            <p class="card-text text-left">{{comentario ? comentario : ''}}</p>
            <!-- <a href="#" class="btn btn-primary">Go somewhere</a> -->
        </div>
    </div>
</template>

<script>
export default {
    name: 'TarjetaComentarios',
    data() {
        return {
            publicPath: process.env.BASE_URL
        }
    },
    props: {
        title: String,
        comentario: String,
        imgUrl: String,
        stars: Boolean
    }
}
</script>

<style>
/* purgecss ignore */
.title-style {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.2;
}
</style>